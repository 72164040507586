enum sakStatus {
    horing = "På høring",
    behandles = "Behandles",
    vedtatt = "Vedtatt",
    ingen = "Ingen status",
}

enum QUERY_PARAMS {
    FYLKE = "fylke",
    KOMMUNE = "kommune",
    SAKSNIVAA = "saksnivaa",
    SVARFRIST = "svarfrist",
    FRITEKST = "fritekst",
}

export { sakStatus, QUERY_PARAMS };
