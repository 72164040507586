import React from "react";

function LightBulbIcon() {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M6.87734 18.2481C6.87773 18.4938 6.95039 18.7344 7.08672 18.9391L7.7543 19.9426C7.86834 20.1141 8.02302 20.2548 8.20457 20.3521C8.38613 20.4495 8.58893 20.5004 8.79492 20.5004H11.2055C11.4115 20.5004 11.6143 20.4495 11.7958 20.3521C11.9774 20.2548 12.1321 20.1141 12.2461 19.9426L12.9137 18.9391C13.0499 18.7343 13.1228 18.494 13.123 18.2481L13.1246 16.75H6.87539L6.87734 18.2481ZM3.125 7.375C3.125 9.10821 3.76758 10.6895 4.82656 11.8977C5.47188 12.634 6.48125 14.1723 6.86602 15.4699C6.86758 15.4801 6.86875 15.4902 6.87031 15.5004H13.1297C13.1312 15.4902 13.1324 15.4805 13.134 15.4699C13.5188 14.1723 14.5281 12.634 15.1734 11.8977C16.2324 10.6895 16.875 9.10821 16.875 7.375C16.875 3.57071 13.7855 0.488284 9.97852 0.500003C5.99375 0.512112 3.125 3.74102 3.125 7.375ZM10 4.25C8.27695 4.25 6.875 5.65196 6.875 7.375C6.875 7.72032 6.59531 8 6.25 8C5.90469 8 5.625 7.72032 5.625 7.375C5.625 4.9625 7.5875 3 10 3C10.3453 3 10.625 3.27969 10.625 3.625C10.625 3.97032 10.3453 4.25 10 4.25Z"
                    fill="#141414"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default LightBulbIcon;
