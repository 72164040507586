import React, {createContext, ReactNode, useContext, useState,} from "react";
import Sak from "../components/common/sak/Sak";
import {useNameMapping} from "./NameMappingContext";
import {todayISOString} from "../utils/utils";

type InitialState = {
    isColumn(col: SORT_COLUMN): boolean;
    isOrder(ord: SORT_ORDER): boolean;
    getOrder(): SORT_ORDER;
    requestSort(col: SORT_COLUMN): void;
    sortSaker(saker: Sak[]): Sak[];
};

type Props = {
    children: ReactNode;
};

export enum SORT_ORDER {
    ASC = "asc",
    DESC = "desc",
}

export enum SORT_COLUMN {
    TITTEL = "tittel",
    STATUS = "status",
    FRIST = "frist",
    OMRADE = "omrade",
    AREAL = "areal",
    SAKTYPE = "type",
    OPPRETTET = "opprettet",
    OPPDATERT = "oppdatert",
}

/**
 * Initially used to hold sorting state while navigating from SakerOverview to Sak and back.
 * Also takes care of the actual sorting of the Table of Sak-objects.
 */

const SortingContext = createContext<InitialState | null>(null);

function SortingContextProvider(props: Props) {

    const {children} = props;

    const [column, setColumn] = useState(SORT_COLUMN.FRIST);
    const [order, setOrder] = useState(SORT_ORDER.ASC);

    const isColumn = (col: SORT_COLUMN) => {
        return column === col;
    };

    const isOrder = (ord: SORT_ORDER) => {
        return order === ord;
    };

    const getOrder = () => {
        return order;
    };

    const toggleOrder = () => {
        if (order === SORT_ORDER.ASC) {
            setOrder(SORT_ORDER.DESC)
        } else {
            setOrder(SORT_ORDER.ASC)
        }
    }

    function requestSort(sortByCol: SORT_COLUMN) {
        if (isColumn(sortByCol)) {
            toggleOrder();
        } else {
            setColumn(sortByCol);
            setOrder(SORT_ORDER.ASC);
        }
    }

    function sortSaker(saker: Sak[]) {
        let sakerToSort: Sak[] = [...saker];

        if (isColumn(SORT_COLUMN.TITTEL)) {
            return sortTitle(sakerToSort);
        } else if (isColumn(SORT_COLUMN.OMRADE)) {
            return sortOmrade(sakerToSort);
        } else if (isColumn(SORT_COLUMN.FRIST)) {
            return sortFrist(sakerToSort);
        } else if (isColumn(SORT_COLUMN.STATUS)) {
            return sortStatus(sakerToSort);
        } else if (isColumn(SORT_COLUMN.AREAL)) {
            return sortAreal(sakerToSort);
        } else if (isColumn(SORT_COLUMN.SAKTYPE)) {
            return sortSakstype(sakerToSort);
        } else if (isColumn(SORT_COLUMN.OPPRETTET)) {
            return sortOpprettet(sakerToSort);
        } else if (isColumn(SORT_COLUMN.OPPDATERT)) {
            return sortOppdatert(sakerToSort);
        } else {
            return saker;
        }
    }

    function sortTitle(saker: Sak[]) {
        saker.sort((i, j) => {
            if (isOrder(SORT_ORDER.ASC)) {
                return i.tittel
                    .toLowerCase()
                    .localeCompare(j.tittel.toLowerCase());
            } else {
                return j.tittel
                    .toLowerCase()
                    .localeCompare(i.tittel.toLowerCase());
            }
        });
        return saker;
    }

    const getOmraadeString = useNameMapping().getOmraadeTekst;

    function sortOmrade(saker: Sak[]) {

        saker.sort((i, j) => {
            if (
                getOmraadeString(
                    i.nasjonal,
                    i.fylker,
                    i.kommuner
                ).toLowerCase() <
                getOmraadeString(j.nasjonal, j.fylker, j.kommuner).toLowerCase()
            ) {
                return isOrder(SORT_ORDER.ASC) ? -1 : 1;
            }
            if (
                getOmraadeString(
                    i.nasjonal,
                    i.fylker,
                    i.kommuner
                ).toLowerCase() >
                getOmraadeString(j.nasjonal, j.fylker, j.kommuner).toLowerCase()
            ) {
                return isOrder(SORT_ORDER.ASC) ? 1 : -1;
            }
            return 0;
        });
        return saker;
    }

    function sortFrist(saker: Sak[]) {

        // sortering på samme måte som default fra backend (ref. kantega.fnf.service.SakService.sorterSaker)

        if (saker.length == 0) {
            return saker;
        }

        const sakerUtenFristSortedByOpprettetAsc = saker
            .filter(s => s.frist == null)
            .sort((sA, sB) => {
                if (sA.opprettet != null && sB.opprettet != null) {
                    return sA.opprettet.localeCompare(sB.opprettet);
                }
                return 0;
            });

        const todayIsoString = todayISOString();

        const sakerMedFristAfterTodaySortedByFristAsc = saker
            .filter(s => (s.frist != null && s.frist > todayIsoString))
            .sort((sA, sB) => {
                if (sA.frist != null && sB.frist != null) {
                    return sA.frist.localeCompare(sB.frist);
                }
                return 0; //will never happen
            });

        const sakerMedFristBeforeOrTodaySortedByFristDesc = saker
            .filter(s => (s.frist != null && s.frist <= todayIsoString))
            .sort((sA, sB) => {
                if (sA.frist != null && sB.frist != null) {
                    return sB.frist.localeCompare(sA.frist);
                }
                return 0; //will never happen
            });

        const defaultSortedSaker: Sak[] = [
            ...sakerMedFristAfterTodaySortedByFristAsc,
            ...sakerUtenFristSortedByOpprettetAsc,
            ...sakerMedFristBeforeOrTodaySortedByFristDesc];

        if (saker.length != defaultSortedSaker.length) {
            throw new Error("Sorting error (" + saker.length + " vs " + defaultSortedSaker.length + " saker)");
        }

        if (isOrder(SORT_ORDER.ASC)) {
            return defaultSortedSaker;
        } else {
            return defaultSortedSaker.reverse();
        }
    }

    function sortStatus(saker: Sak[]) {
        saker.sort((i, j) => {
            if (
                i.status.toLowerCase() <
                j.status.toLowerCase()
            ) {
                return isOrder(SORT_ORDER.ASC) ? -1 : 1;
            }
            if (
                i.status.toLowerCase() >
                j.status.toLowerCase()
            ) {
                return isOrder(SORT_ORDER.ASC) ? 1 : -1;
            }
            return 0;
        });
        return saker;
    }

    function sortAreal(saker: Sak[]) {
        saker.sort((i, j) => {
            if (i.areal !== j.areal) {
                if (i.areal == undefined) {
                    return isOrder(SORT_ORDER.ASC) ? -1 : 1;
                } else if (j.areal == undefined) {
                    return isOrder(SORT_ORDER.ASC) ? 1 : -1;
                } else if (i.areal < j.areal) {
                    return isOrder(SORT_ORDER.ASC) ? -1 : 1;
                } else if (i.areal > j.areal) {
                    return isOrder(SORT_ORDER.ASC) ? 1 : -1;
                } else {
                    console.log("Sort error (areal) ", i.areal, " vs ", j.areal)
                }
            }
            return 0;
        });
        return saker;
    }

    function sortSakstype(saker: Sak[]) {
        saker.sort((i, j) => {
            if (i.sakstype !== j.sakstype) {
                if (i.sakstype == undefined) {
                    return isOrder(SORT_ORDER.ASC) ? -1 : 1;
                } else if (j.sakstype == undefined) {
                    return isOrder(SORT_ORDER.ASC) ? 1 : -1;
                } else {
                    if (isOrder(SORT_ORDER.ASC)) {
                        return i.sakstype
                            .toLowerCase()
                            .localeCompare(j.sakstype.toLowerCase());
                    } else {
                        return j.sakstype
                            .toLowerCase()
                            .localeCompare(i.sakstype.toLowerCase());
                    }
                }
            }
            return 0;
        });
        return saker;
    }

    function sortOpprettet(saker: Sak[]) {
        //Kommer på ISO-format, så kan sammenligne leksikografisk
        saker.sort((i, j) => {
            // console.log("sort ", i.opprettet, " vs ", j.opprettet)
            if (i.opprettet !== undefined && j.opprettet !== undefined) {
                if (isOrder(SORT_ORDER.ASC)) {
                    return i.opprettet.localeCompare(j.opprettet);
                } else {
                    return j.opprettet.localeCompare(i.opprettet);
                }
            }
            return 0;
        });
        return saker;
    }

    function sortOppdatert(saker: Sak[]) {
        //Kommer på ISO-format, så kan sammenligne leksikografisk
        saker.sort((i, j) => {
            // console.log("sort ", i.oppdatert, " vs ", j.oppdatert)
            if (i.oppdatert !== undefined && j.oppdatert !== undefined) {
                if (isOrder(SORT_ORDER.ASC)) {
                    return i.oppdatert.localeCompare(j.oppdatert);
                } else {
                    return j.oppdatert.localeCompare(i.oppdatert);
                }
            }
            return 0;
        });
        return saker;
    }

    return (
        <SortingContext.Provider value={{
            isColumn: isColumn,
            isOrder: isOrder,
            getOrder: getOrder,
            requestSort,
            sortSaker,
        }}
        >
            {children}
        </SortingContext.Provider>
    );
}

export default SortingContextProvider;

export const useSortingContext = () => {

    const context = useContext(SortingContext);
    if (!context) {
        throw new Error(
            `useSortingContext must be used within the SortingContextProvider`
        );
    }

    return context;
};
