import {Saksnivaa, SaksnivaaNasjonal} from "../../constants/Saksnivaa";
import {Svarfrist} from "../../constants/Svarfrist";

class SakFilter {

    private readonly filter: Filter;

    constructor(selectedSaksnivaaParams: string[], selectedFylker: number[], selectedKommune: number[], selectedSaksfist: string[], selectedFritekst: string | null) {
        this.filter = {
            saksnivaa: selectedSaksnivaaParams,
            fylker: selectedFylker,
            kommuner: selectedKommune,
            svarfrist: selectedSaksfist,
            fritekst: selectedFritekst
        };
    }

    getFilter() {
        return this.filter;
    }

    getFritekst() {
        return this.filter.fritekst;
    }

    setFritekst(value: string) {
        this.filter.fritekst = value;
    }

    getSaksnivaa() {
        return this.filter.saksnivaa;
    }

    getSvarfrist() {
        return this.filter.svarfrist;
    }

    getFylker() {
        return this.filter.fylker;
    }

    getKommuner() {
        return this.filter.kommuner;
    }

    hasSaksnivaa(nivaa: Saksnivaa) {
        return this.filter.saksnivaa.includes(nivaa.paramValue);
    }

    hasSvarfrist(frist: Svarfrist) {
        return this.filter.svarfrist.includes(frist.paramValue);
    }

    isEmpty() {
        const fylkeIsEmpty = this.filter.fylker.length == 0;
        const kommuneIsEmpty = this.filter.kommuner.length == 0;
        const saksnivaaIsEmpty = this.filter.saksnivaa.length == 0;
        const svarfristIsEmpty = this.filter.svarfrist.length == 0;
        const fritekstIsEmpty = this.filter.fritekst == null || this.filter.fritekst.trim().length == 0;
        return fylkeIsEmpty && kommuneIsEmpty && saksnivaaIsEmpty && svarfristIsEmpty && fritekstIsEmpty;
    }

    hasSearchParamsForVarsel() {
        const filterOmraadeFylke = this.filter.fylker.length > 0;
        const filterOmraadeKommune = this.filter.kommuner.length > 0;
        const filterSaksnivaaIsNasjonalOnly = this.filter.saksnivaa.length == 1 && this.filter.saksnivaa.includes(SaksnivaaNasjonal.paramValue);
        const fritekstIsEmpty = this.filter.fritekst == null || this.filter.fritekst.trim().length == 0;
        return filterOmraadeFylke || filterOmraadeKommune || filterSaksnivaaIsNasjonalOnly || !fritekstIsEmpty;
    }

    hasSearchParamsForEksport() {
        const filterOmraadeFylke = this.filter.fylker.length > 0;
        const filterOmraadeKommune = this.filter.kommuner.length > 0;
        const filterSaksnivaa = this.filter.saksnivaa.length > 0;
        const filterSvarfrist = this.filter.svarfrist.length > 0;
        const fritekstIsEmpty = this.filter.fritekst == null || this.filter.fritekst.trim().length == 0;
        return filterSaksnivaa || filterOmraadeFylke || filterOmraadeKommune || filterSvarfrist || !fritekstIsEmpty;
    }

    getCount() {
        const numFylker = this.filter.fylker.length;
        const numKommuner = this.filter.kommuner.length;
        const numSaksnivaa = this.filter.saksnivaa.length;
        const numSvarfrist = this.filter.svarfrist.length;
        const numFritekst = this.filter.fritekst != null ? (this.filter.fritekst.trim().length > 0 ? 1 : 0) : 0;
        return numFylker + numKommuner + numSaksnivaa + numSvarfrist + numFritekst;
    }

    static getNullstillTextVerbose(count: number) {
        if (count == 0) {
            return "Ingen filtre"
        } else if (count == 1) {
            return "Nullstill filteret";
        } else if (count == 2) {
            return "Nullstill begge filtrene";
        } else {
            return "Nullstill alle " + count + " filtrene";
        }
    }

    static getNullstillTextMedium(count: number) {
        if (count == 0) {
            return "Ingen filtre"
        } else if (count == 1) {
            return "Nullstill 1 filter";
        } else {
            return "Nullstill " + count + " filtre";
        }
    }

    static getNullstillTextShort() {
        return "Nullstill filter"
    }
}

export default SakFilter;