import React, {useState} from 'react';
import SakFilter from "../SakFilter";
import {IconButton, InputBase, makeStyles, Paper, Theme} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

type Props = {
    filter: SakFilter;
    origFritekst: string | null;
    handleClick: (txt: string | null) => void;
};

type FormValidationErrors = {
    text?: string;
};

const VALID_FRITEKST_REGEXP = /^[a-zA-Z0-9æøåÆØÅ\s\-_.,:+/*%]+$/;

const useStyles = makeStyles((theme: Theme) => (
    {
        root: {
            // backgroundColor: "yellow",
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 'inherit',

            marginRight: '20px',
            '@media(max-width: 600px)' : {
                // backgroundColor: "yellow",
                marginRight: 'inherit',
            }
        },

        rootError: {
            // backgroundColor: "yellow",
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 'inherit',

            marginRight: '20px',
            '@media(max-width: 600px)' : {
                marginRight: 'inherit',
            },

            borderColor: "red",
        },

        rootFilterActive: {
            // backgroundColor: "yellow",
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 'inherit',

            marginRight: '20px',
            '@media(max-width: 600px)' : {
                marginRight: 'inherit',
            },

            borderColor: "var(--blue)",
            borderWidth: "2px",
        },

        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        error: {
            color: "red",
            fontSize: "14px"
        },
}),
);

const MINIMUM_LENGTH = 3;

const FritekstFilter = (props: Props) => {

    const classes = useStyles();

    const [formText, setFormText] = useState<string | null>(props.filter.getFritekst());
    const [formValidationErrors, setFormValidationErrors] = useState<FormValidationErrors>({});

    function isMinumumLengthOrEmpty(str: string | null, number: number) {
        return ((str === null) || (str?.length !== undefined && (str?.trim().length === 0 || str?.trim().length >= number)));
    }

    function validateText(value: string | null) {
        const validationErrors: FormValidationErrors = {};

        if (value !== null) {
            if (!isValidFritekst(value)) {
                validationErrors.text = "Ugyldig tekst";
            }
            setFormValidationErrors(validationErrors);
        }
        return validationErrors;
    }

    function localHandleChangeFormData(e: React.ChangeEvent<HTMLInputElement>) {
        // e.preventDefault(); //?
        props.filter.setFritekst(e.target.value);
        // const fritekst = props.filter.getFritekst();
        // console.log("set ", e.target.value, ", get gir: ", fritekst);

        //NB! en sideeffekt av denne er at vi mellomlagrer i en variabel som er linket til props - lokalt
        //    For de andre filtrene er det URL'en som er lager for state, mens her må vi ha et mellomlager
        setFormText(e.target.value)
    }

    function validateAndHandle() {

        //kan hente fra begge her - de er i sync
        const fritekst = props.filter.getFritekst();
        // const fritekst = formText;

        const validationErrors = validateText(fritekst);

        if (Object.keys(validationErrors).length === 0) {
            const trimmedText = (fritekst !== null) ? fritekst?.trim() : null;
            props.handleClick(trimmedText);
        }
    }

    function localHandleClick(e: React.MouseEvent) {
        validateAndHandle();
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.code == "Enter") {
            if (isMinumumLengthOrEmpty(getValueForButtonActivation(), MINIMUM_LENGTH)) {
                validateAndHandle();
            }
        }
    }

    function hasActiveFritekstParam() {
        // const origFritekst = props.filter.getFritekst();
        const origFritekst = props.origFritekst;
        return (origFritekst !== null) && (origFritekst.length > 0);
    }

    function getValueForInput() {
        const fraProps = props.filter.getFritekst() != null ? ("" + props.filter.getFritekst()) : "";
        // const fraForm = formText;
        return fraProps;
    }

    function getValueForButtonActivation() {
        const fraProps = props.filter.getFritekst() != null ? ("" + props.filter.getFritekst()) : "";
        // const fraForm = formText;
        return fraProps;
    }

    function getPlaceholderText() {
        // return hasActiveFritekstParam() ? "søk for å fjerne" : "søk minst " + MINIMUM_LENGTH + " tegn";
        return "søk minst " + MINIMUM_LENGTH + " tegn";
    }

    return (
        // <Tooltip
        //     title={"Hjælp :)"}
        //     TransitionComponent={Zoom}
        // >
        <div>
        {/*<Paper component="form" className={classes.root}>*/}
        <Paper component="div" variant={"outlined"}
               className={(formValidationErrors.text === undefined) ? (hasActiveFritekstParam() ? classes.rootFilterActive : classes.root) : classes.rootError}
        >
            <InputBase
                className={classes.input}
                value={getValueForInput()}
                placeholder={getPlaceholderText()}
                onChange={localHandleChangeFormData}
                onKeyDown={handleKeyDown}
            />
            <IconButton
                type="button"
                className={classes.iconButton}
                // aria-label="search"
                disabled={!isMinumumLengthOrEmpty(getValueForButtonActivation(), MINIMUM_LENGTH)}
                onClick={localHandleClick}
            >
                <SearchIcon />
            </IconButton>
        </Paper>
            {(formValidationErrors.text !== undefined) && (
                <p className={classes.error}>{formValidationErrors.text}</p>
            )}
        </div>
        // </Tooltip>
    );
}

export default FritekstFilter;

export function isValidFritekst(value: string) {
    return value.length === 0 || VALID_FRITEKST_REGEXP.test(value);
}
