import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API_SAKER_PATH, SAK_PATH_WITH_ID } from "../../../constants/urls";
import { emptySak } from "../../../constants/initializedTypes";
import SakForm from "../sakForm/SakForm";
import Sak from "../../common/sak/Sak";
import { UseKoordinatorContext } from "../../../context/KoordinatorContext";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { CircularProgress } from "@material-ui/core";
import SaveSakDialog from "../saveSakDialog/SaveSakDialog";
import ErrorPage from "../../common/errorPage/ErrorPage";
import styles from "./editSak.module.css";

const EditSak = () => {
    const { id } = useParams<{ id: string }>();
    const [sak, setSak] = useState<Sak>(emptySak);
    const token = UseKoordinatorContext().token;
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetch(API_SAKER_PATH + id)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                throw new Error(res.statusText);
            })
            .then((res) => {
                setSak({
                    ...res,
                });
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                setError(true);
                setIsLoading(false);
                console.log(error);
            });
    }, [id]);

    useEffect(() => {
        if (success) {
            setOpen(true);
        }
    }, [success]);

    function handleToSak() {
        history.push(SAK_PATH_WITH_ID(id));
    }

    function handleSubmit(e: any, formData: Sak) {
        e.preventDefault();

        fetch(API_SAKER_PATH + id, {
            method: "PUT",
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    res.json();
                    setSuccess(true);
                }
            })
            .then(e.target.reset())
            .catch((error) => {
                console.log(error);
            });
    }

    if (error) {
        return <ErrorPage />;
    }

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <div className={styles.editSakMain}>
            <h2>Rediger sak</h2>
            <SakForm
                sak={sak}
                handleSubmit={handleSubmit}
                isEditSak={true} />
            {success && (
                <SaveSakDialog
                    success={open}
                    handleToSak={handleToSak}
                    isEditSak={true}
                />
            )}
        </div>
    );
};

export default withAuthenticationRequired(EditSak, {
    onRedirecting: () => <CircularProgress />,
});
