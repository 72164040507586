import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    ADMIN_EDIT_SAK_PATH_WITH_ID, ADMIN_NEW_SAK_PATH,
    API_SAKER_PATH,
    FNF_PAAVIRKE_URL,
    FNF_SAKSPROSESS_URL,
} from "../../../constants/urls";
import { emptySak } from "../../../constants/initializedTypes";
import InformationBox from "../informationBox/InformationBox";
import { useNameMapping } from "../../../context/NameMappingContext";
import dateFormatConverter from "../../../utils/dateFormatConverter";
import dateTimeFormatConverter from "../../../utils/dateTimeFormatConverter";
import DeleteSakAlert from "../../koordinator/deleteSakAlert/DeleteSakAlert";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./sak.module.css";
import "../../../styles/App.css";
import "react-quill/dist/quill.snow.css";
import ButtonWithLink from "../buttons/ButtonWithLink";
import ArrowForwardTwoToneIcon from "@material-ui/icons/ArrowForwardTwoTone";
import {CircularProgress, useMediaQuery, useTheme} from "@material-ui/core";
import ErrorPage from "../errorPage/ErrorPage";
import {Add, Launch} from "@material-ui/icons";
import ButtonWithAction from "../buttons/ButtonWithAction";
import LightBulbIcon from "../../../icons/LightBulbIcon";
import DOMPurify from "dompurify";

const Sak = () => {
    const { isAuthenticated } = useAuth0();

    const { id } = useParams<{ id: string }>();
    const [sak, setSak] = useState<Sak>(emptySak);
    const history = useHistory();
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const loadingContext = useNameMapping().isLoading;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const cleanHTML = DOMPurify.sanitize(sak.sammendrag, {
        USE_PROFILES: { html: true },
    });

    function redigerSak(id: string) {
        history.push(ADMIN_EDIT_SAK_PATH_WITH_ID(id));
    }

    function nySak() {
        history.push(ADMIN_NEW_SAK_PATH);
    }

    useEffect(() => {
        setIsLoading(true);
        fetch(API_SAKER_PATH + id)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                throw new Error(res.statusText);
            })
            .then((res) => {
                setSak({
                    ...res,
                });
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                setError(true);
                setIsLoading(false);
                console.log(error);
            });
    }, [id]);

    if (error) {
        return <ErrorPage />;
    }
    if (isLoading || loadingContext) {
        return <CircularProgress />;
    }

    return (
        <div className={styles.sakMain}>
            <div className={styles.content}>
                {isAuthenticated && (
                    <div className={styles.actionContainer}>
                        <DeleteSakAlert />
                        <ButtonWithAction
                            title={isMobileScreen ? "Rediger" : "Rediger sak"}
                            color={theme.palette.info.light}
                            onClick={() => redigerSak(sak.id)}
                        />
                        <ButtonWithAction
                            title={isMobileScreen ? "Ny" : "Ny sak"}
                            onClick={nySak}
                            color={theme.palette.info.light}
                            icon={<Add/>}
                        />
                    </div>
                )}

                <h1 className="main-heading">{sak.tittel}</h1>
                <InformationBox
                    frist={dateFormatConverter(sak.frist)}
                    areal={sak.areal}
                    nasjonal={sak.nasjonal}
                    fylker={sak.fylker}
                    kommuner={sak.kommuner}
                    status={sak.status}
                />

                <div className={styles.externalLinkContainer}>
                    <ButtonWithLink
                        url={FNF_PAAVIRKE_URL}
                        title={"Hvordan påvirke?"}
                        icon={<LightBulbIcon />}
                        color={theme.palette.primary.light}
                    />

                    <ButtonWithLink
                        url={FNF_SAKSPROSESS_URL}
                        title={"Saksprosessen"}
                        icon={<ArrowForwardTwoToneIcon />}
                        color={theme.palette.secondary.main}
                    />
                </div>
                <h2 className="sub-heading">Hva gjelder saken?</h2>
                <div className="border-sub-heading" />

                <div
                    // NB! to get Quill editor's styling when viewing Quill-generated (custom!) markup!
                    //     ref. https://github.com/slab/quill/issues/3193#issuecomment-1954938850
                    className={"ql-editor"}

                    dangerouslySetInnerHTML={{ __html: cleanHTML }}
                />

                {sak.sakstype && (
                    <span className={styles.inlineInformation}>
                        <label htmlFor="sakType">Sakstype:</label>
                        <p id="sakType">
                            {sak.sakstype.replaceAll(";;", "; ")}
                        </p>
                    </span>
                )}

                {sak.saksnummer && (
                    <span className={styles.inlineInformation}>
                        <label htmlFor="sakNumber">Saksnummer:</label>
                        <p id="sakNumber">{sak.saksnummer}</p>
                    </span>
                )}

                {sak.myndighet && (
                    <span className={styles.inlineInformation}>
                        <label htmlFor="sakNumber">Avsender:</label>
                        <p id="sakNumber">{sak.myndighet}</p>
                    </span>
                )}

                {sak.lenke && (
                    <div className={styles.actionContainer}>
                        <ButtonWithLink
                            url={sak.lenke}
                            title="Les hele saken"
                            color={theme.palette.primary.light}
                            icon={<Launch />}
                        />
                    </div>
                )}

                <div className={styles.updateInfo}>
                    <p>
                        Sist oppdatert av FNF{" "}
                        {dateTimeFormatConverter(sak.oppdatert)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Sak;
