import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { BASE_PATH } from "../../../constants/urls";
import { useTheme } from "@material-ui/core";
import ButtonWithAction from "../../common/buttons/ButtonWithAction";
import ArrowForwardTwoToneIcon from "@material-ui/icons/ArrowForwardTwoTone";
import { Close } from "@material-ui/icons";
import styles from "./saveSakDialog.module.css";
import { useHistory } from "react-router-dom";

type Props = {
    success: boolean;
    handleToSak: () => void;
    isEditSak: boolean;
};

const SaveSakDialog = (props: Props) => {
    const theme = useTheme();
    const history = useHistory();

    function toMainPage() {
        history.push(BASE_PATH);
    }

    return (
        <Dialog
            open={props.success}
            onClose={() => {
                return;
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.isEditSak ? "Oppdatert!" : "Publisert!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.isEditSak
                        ? "Saken er nå oppdatert, og er synlig på Høringsradaren. Du kan redigere eller slette saken senere."
                        : "Saken er nå publisert for offentligheten på Høringsradaren. Du kan redigere eller slette saken senere."}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.actionButtons}>
                <ButtonWithAction
                    title={"Til forsiden"}
                    color={theme.palette.grey["300"]}
                    onClick={toMainPage}
                    icon={<Close />}
                />

                <ButtonWithAction
                    title={"Se saken"}
                    color={theme.palette.secondary.main}
                    onClick={props.handleToSak}
                    icon={<ArrowForwardTwoToneIcon />}
                />
            </DialogActions>
        </Dialog>
    );
};

export default SaveSakDialog;
