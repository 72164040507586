import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import {useHistory} from "react-router-dom";
import {
    API_VARSEL_ABONNEMENT_AKTIVERT_PATH,
    API_VARSEL_PATH,
    ERROR_PATH,
    FNF_VARSEL_GDPR_URL,
    VARSEL_STOP_PATH_WITH_ID
} from "../../../constants/urls";
import {makeStyles, TextField, useMediaQuery, useTheme} from "@material-ui/core";
import "../../../styles/App.css";
import ButtonWithActionAndTooltip from "../buttons/ButtonWithActionAndTooltip";
import {Close, Launch} from "@material-ui/icons";
import styles from "./varselCreateForm.module.css";
import {Alert} from "@material-ui/lab";
import VarselIcon from "../../../icons/VarselIcon";
import ButtonWithAction from "../buttons/ButtonWithAction";
import StaticSearchChips from "../chips/StaticSearchChips";
import SakFilter from "../SakFilter";

type Props = {
    varselAbonnementAktivert: boolean;
    sakFilter: SakFilter;
};

type VarselFormData = {
    svarfrist: string[];
    saksnivaa: string[];
    fylker: number[];
    kommuner?: number[];
    epost: string;
    fritekst: string | null;
}

type FormValidationErrors = {
    epost?: string;
    filter?: string;
};

const useStyles = makeStyles((theme) => ({
    inputField: {
        width: "100%",
        maxWidth: "500px",
    },
    buttonAction: {
        borderRadius: "40px",
        padding: "6px 16px",
        maxHeight: "40px",
        boxShadow: "none",
        marginLeft: "auto",
        marginTop: "4px",
        marginBottom: "4px",
        marginRight: "16px",
        textTransform: "none",
        "&:hover, &:focus": {
            boxShadow: "none",
        },
    },
    buttonActionMobile: {
    },
    labelStatus: {
        fontSize: "14px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        marginLeft: "4px",
        fontSize: "20px",
    },
    alertSuccess: {
        backgroundColor: theme.palette.secondary.main,
    },
    alertError: {
        backgroundColor: theme.palette.error.main,
    }
}));


const SERVICE_UNAVAILABLE_TEXT = "Tjenesten er midlertidig deaktivert - prøv igjen senere";

const VarselCreateForm = (props: Props) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [serviceUnavailable, setServiceUnavailable] = useState(false);
    const [error, setError] = useState(false);
    const [formValidationErrors, setFormValidationErrors] = useState<FormValidationErrors>({});
    const [formData, setFormData] = useState<VarselFormData>({
        saksnivaa: props.sakFilter.getSaksnivaa(),
        fylker: props.sakFilter.getFylker(),
        kommuner: props.sakFilter.getKommuner(),
        svarfrist: props.sakFilter.getSvarfrist(),
        epost: "",
        fritekst: props.sakFilter.getFritekst()
    });
    const [uuid, setUUID] = useState<string>("");
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles(theme);



    const handleClickOpen = () => {
        setOpen(true);
        setFormData(formData);
    };

    function clearFormData() {
        setFormData({
            saksnivaa: props.sakFilter.getSaksnivaa(),
            fylker: props.sakFilter.getFylker(),
            kommuner: props.sakFilter.getKommuner(),
            svarfrist: props.sakFilter.getSvarfrist(),
            epost: "",
            fritekst: props.sakFilter.getFritekst()
        });
    }

    const handleClose = () => {
        setFormValidationErrors({});
        clearFormData();
        setOpen(false);
    };

    const handleCloseSnackbar = () => {
        //hvis ikke lukket eksplisitt fra alert
        setUUID("");
        setOpen(false);
        setServiceUnavailable(false);
    };

    const handleCloseAlert = () => {
        setUUID("");
        setOpen(false);
        setServiceUnavailable(false);
    };

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    function isValidEmail(value: string) {
        const regex = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
        return regex.test(value);
    }

    async function isVarselAbonnementAktivert() {
        const res = await fetch(`${API_VARSEL_ABONNEMENT_AKTIVERT_PATH}`);
        const json = await res.json();
        return Boolean(json);
    }

    async function validateForm() {
        const validationErrors: FormValidationErrors = {};

        if (!formData.epost) {
            validationErrors.epost = "Husk å fylle inn epost";

        } else if (!isValidEmail(formData.epost)) {
            validationErrors.epost = "Ugyldig epost";

        } else if (! await isVarselAbonnementAktivert()) {
            validationErrors.epost = SERVICE_UNAVAILABLE_TEXT;
        } else if (formData.fritekst !== null) {
            validationErrors.filter = "Dessverre ikke mulig å abonnere på fritekst ennå";
        }

        setFormValidationErrors(validationErrors);
        return validationErrors;
    }

    async function handleSubmit() {
        const validationErrors = await validateForm();

        // console.log("QP: ", JSON.stringify(formData));

        if (Object.keys(validationErrors).length === 0) {

            fetch(API_VARSEL_PATH, {
                method: "POST",
                body: JSON.stringify(formData),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.ok || res.status === 503) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                })
                .then((res) => {
                    if (res.error != null) {
                        console.log("Opprettet IKKE varsel pga: " + res.error);
                        setUUID("");
                        setServiceUnavailable(true);
                    } else {
                        setUUID(res.id);
                    }

                    handleClose();
                })
                .catch((error) => {
                    setError(true);
                    console.log(error);
                });
        } else {
            console.log("Error", validationErrors)
        }
    }

    if (error) {
        console.log("Error", error);
        history.push(ERROR_PATH);
    }


    return (
        <div style={{
            // backgroundColor: "lightgreen",
            // border:"2px solid darkgreen",
        }}>
            <ButtonWithActionAndTooltip
                title={"Abonner"}
                color={theme.palette.info.light}
                classes={{root: classes.buttonAction}}
                onClick={handleClickOpen}
                disabled={!props.sakFilter.hasSearchParamsForVarsel() || !props.varselAbonnementAktivert}
                icon={<VarselIcon />}
                enabledTooltip={"Bli varslet om nye og endrede saker med valgt filter"}
                disabledTooltip={!props.varselAbonnementAktivert ? "Tjenesten er midlertidig deaktivert" : "Velg først et område (eller Landsdekkende saksnivå) å abonnere på"}
            />

            <Snackbar
                key={Math.random()}
                open={uuid.length > 0}
                autoHideDuration={null}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseAlert} severity="success" variant="filled" classes={{root: classes.alertSuccess}}>
                    Abonnement er opprettet (<a href={VARSEL_STOP_PATH_WITH_ID(uuid)}>angre</a>)
                </Alert>
            </Snackbar>
            <Snackbar
                key={Math.random()}
                open={serviceUnavailable}
                autoHideDuration={null}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseAlert} severity="error" variant="filled" classes={{root: classes.alertError}}>
                    Abonnement feilet:<br/>{SERVICE_UNAVAILABLE_TEXT}
                </Alert>
            </Snackbar>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        boxShadow: "none",
                    },
                }}
                fullScreen={isMobileScreen}
            >
                <DialogContent>

                    <DialogTitle>{isMobileScreen ? "Abonner" : "Abonner på søket"}</DialogTitle>

                    <DialogContentText id="alert-dialog-description">
                        Du blir varslet på epost for nye og endrede saker basert på filteret:
                        {/*Du blir varslet på epost for nye og endrede saker i området:*/}
                        <StaticSearchChips
                            filter={props.sakFilter}
                            fritekstErrorMsg={formValidationErrors.filter}
                        />
                    </DialogContentText>

                    <TextField
                        autoFocus
                        multiline={false}
                        classes={{ root: classes.inputField }}
                        name="epost"
                        label="Epostadresse"
                        placeholder="din epost"
                        type="email"
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        size="small"
                        variant="outlined"

                        error={!!formValidationErrors.epost}
                        helperText={
                            formValidationErrors.epost ? formValidationErrors.epost : ""
                        }
                    />

                    <a
                        href={FNF_VARSEL_GDPR_URL}
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Slik brukes epostadressen din <Launch />{" "}
                    </a>

                </DialogContent>
                <DialogActions className={styles.actionButtons}>
                    <ButtonWithAction
                        title={"Avbryt"}
                        color={theme.palette.grey["600"]}
                        onClick={handleClose}
                        icon={<Close />}
                    />
                    <ButtonWithAction
                        title={"Abonner"}
                        color={theme.palette.primary.main}
                        onClick={handleSubmit}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default VarselCreateForm;
