import React from "react";
import ReactQuill, {UnprivilegedEditor} from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./textEditor.css";
import "../sakForm/sakForm.module.css";
import {DeltaStatic, Sources} from "quill";

type Props = {
    sammendrag: string;
    handleChange: (value: string, delta: DeltaStatic, source: Sources, editor: UnprivilegedEditor) => void;
};

function TextEditor(props: Props) {
    const quillModules = {
        toolbar: [
            ["bold", "italic", "underline", "link"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
        ],
        // "Bugfix" to prevent Quill inserting a useless line-break before the <ul> elements
        // https://github.com/slab/quill/issues/2905
        // https://github.com/slab/quill/issues/1379
        clipboard: {
            matchVisual: false
        }
    };

    const formats = [
        "bold",
        "italic",
        "underline",
        "link",
        "list",
        "bullet",
        "indent",
    ];

    return (
        <ReactQuill
            value={props.sammendrag}
            onChange={props.handleChange}
            theme={"snow"}
            modules={quillModules}
            formats={formats}
            bounds={".quill"}
        />
    );
}

export default TextEditor;
