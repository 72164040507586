import React, { ReactNode } from "react";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { ADMIN_PATH } from "../../constants/urls";

type Props = {
    children: ReactNode;
};

const Auth0ProviderWithHistory = (props: Props) => {
    const { children } = props;

    const history = useHistory();

    const onRedirectCallback = (appState: AppState) => {
        if (appState.returnTo === ADMIN_PATH) {
            history.push(window.location.pathname);
        } else {
            history.push(appState?.returnTo || window.location.pathname);
        }
    };

    return (
        <Auth0Provider
            domain="hoeringsradaren.eu.auth0.com"
            clientId="RNxWielnmhiDyeycPkd0aVW8ZWWRt1QL"
            redirectUri={window.location.origin}
            audience="https://hoeringsradaren.no"
            scope="create:sak update:sak delete:sak"
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
